<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';
import type { JobModel } from '@/types/jobModel';
import JobListItem from '@/views/career/JobListItem.vue';

defineOptions({
  name: 'WishlistPage',
});

const { $mopI18n, $gtm, $urls } = useNuxtApp();
const route = useRoute();
const classNameRef = ref(['']);
const loadingRef = ref(true);

const { wishlistItemsRef } = useMopWishlist();
const { initTransition } = useMopPageTransitionClient();
const { getCmsStory, trackPageView, cmsStoryModelRef } = useMopCms();
const { getBreadcrumbSchema, handleHeadForCmsPage } = useMopSeo();

onMounted(async () => {
  await getCmsStory('/wishlist');
  trackPageView('Wishlist');
  loadingRef.value = false;

  if (wishlistItemsRef.value.length) {
    $gtm.trackEvent({
      eventName: 'view_item_list',
      customValue: {
        items: [wishlistItemsRef.value.map((item: JobModel) => item.getGtmTrackData())],
      },
    });
  }
});

initTransition(classNameRef, loadingRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t($urls.WISHLIST_PAGE),
      url: route.path,
    },
  ];

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(breadcrumbs),
  };
});
useHead(seoHeadRef);
</script>

<template>
  <div :class="['wishlist-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <template v-if="wishlistItemsRef.length > 0">
        <MopCmsContentElementsSlot id="bodyTop" no-padding :elements="cmsStoryModelRef.getContentElements('bodyTop')" />
        <div class="wishlist-page__items">
          <JobListItem
            v-for="jobItem in wishlistItemsRef"
            :key="jobItem.getId()"
            :job="jobItem"
            class="wishlist-page__item"
          />
        </div>
      </template>
      <MopCmsContentElementsSlot
        v-else
        id="bodyBottom"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyBottom')"
      />
    </div>
  </div>
</template>

<style lang="scss">
.wishlist-page {
  padding: 0 $global-padding;
}

.wishlist-page__items {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  column-gap: $space-8;
  row-gap: $space-8;
  align-content: start;

  @include apply-upto(extralarge) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @include apply-upto(medium) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @include apply-upto(small) {
    grid-template-columns: 1fr;
  }
}

.wishlist-page__item {
  border: 1px solid $black;

  @include apply-from(medium) {
    aspect-ratio: 5 / 6;
  }
}
</style>
